<template>
  <div>
    <div style="display: flex;margin-top: 100px">
      <div style="margin-right: 50px;margin-left: 150px">
        <el-form-item>
          <el-tag>作业工点</el-tag>
          <el-select style="margin-left: 5px;margin-bottom: 20px;width: 200px" v-model="opername" placeholder="请选择洞口"
                     size="small">

            <el-option
                v-for="item in opernames"
                :key="item.flag"
                :label="item.operatingpoint"
                :value="item.operatingpoint"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <div style="margin-right: 50px">
        <el-form-item>
          <el-tag style="margin-right: 5px">劳工姓名</el-tag>
          <el-input style="width: 300px" size="small" v-model="workername" placeholder="请输入劳工姓名" clearable
                    @blur="getel"/>
        </el-form-item>
      </div>

      <div>
        <el-form-item>
          <el-tag style="margin-right: 5px">劳工联系方式</el-tag>
          <el-input style="width: 300px" size="small" v-model="workertel" placeholder="请输入劳工联系方式" clearable/>
        </el-form-item>
      </div>
    </div>

    <div style="margin-left: 1300px;margin-top: 50px">
      <el-button type="primary" @click="inhole">进洞</el-button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ElMessage} from 'element-plus'
import {Decrypt, Encrypt} from "@/utils/secret";
import {setNowDate, setNowHou, setNowMonth, setNowTimes, setNowTimesInHole} from '@/utils/time';

export default {
  name: "worker_info",
  data() {
    return {
      opernames: [],
      opername: '',
      workername: '',
      workertel: '',
    }
  },
  computed: {
    ...mapGetters(['GET_BDST']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
  },
  created() {
    this.axios.post('/sink/getoper', (response) => {
      response.obj.forEach((item, i) => {
        item.forEach((item1, y) => {
          this.opernames.push(item1)
        })
      });
    }, {
      bdst: this.GET_BDST
    })
  },
  methods: {
    getel() {
      this.axios.post('/workers/getWorkerTel', (response) => {
        if (response.obj !== '') {
          const tel = Decrypt(response.obj);
          this.workertel = tel;
        }
      }, {
        opername: this.opername,
        workername: this.workername
      })
    },
    inhole() {
      if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.workertel)) {
        ElMessage.error('请填写正确的手机号')
      } else {
        const tel1 = Encrypt(this.workertel);
        this.axios.post('/workers/addInHole', (response) => {
          if (response.obj) {
            ElMessage({
              message: '提交成功',
              type: 'success',
            });
            this.workertel = '';
            this.workername = '';
            this.opername = ''
          } else {
            ElMessage.error('提交失败，请联系科信部')
          }
        }, {
          opername: this.opername,
          workername: this.workername,
          workerbdst: this.GET_BDST,
          workertel: tel1,
          inopertime: setNowTimesInHole(new Date()),
          outopertime: '1'
        })
      }


    }


  }
}
</script>

<style scoped>

</style>